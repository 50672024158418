import * as React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink, StaticQuery, graphql } from 'gatsby'

import { Container, Row, Col, colors, media } from 'cc-ui-components'
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl'

interface PopularSearchesSectionProps extends InjectedIntlProps {
  sm?: number
  md?: number
  lg?: number
  backgroundColor?: string
}

const Wrapper = styled.div``

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${media.sm`
    div {
      display: none;
    }
  `};
`

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 17px;
  a {
    width: 20%;
    &:hover {
      text-decoration: none;
    }
  }
  ${media.sm`
    flex-direction: column;
    a {
      width: 100%;
      height: 36px;
      p {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
    margin-top: 3px;
  `};
`

const SearchText = styled.p`
  display: flex;
  justify-content: flex-start;
  color: ${colors.lightText};
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  text-transform: uppercase;
  ${media.sm`
    width: 50%;
  `};
`

const SubsectionTitle = styled.p`
  font-size: 20px;
  line-height: 30px;
  margin-top: 10px;
  color: ${colors.text};
  ${media.sm`
    margin-top: 35px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  `};
`

const PopularSearchesSection: React.SFC<PopularSearchesSectionProps> = ({
  sm,
  md,
  lg,
  intl,
  backgroundColor,
}) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulWebpage(filter: { category: { eq: "languages" } }) {
          edges {
            node {
              category
              slug
              title
              linkName
              node_locale
            }
          }
        }
      }
    `}
    render={data => {
      const languagePages = data.allContentfulWebpage.edges.filter(
        (node: any) => node.node.node_locale.startsWith(intl.locale)
      )

      return (
        <Wrapper id={'popular-searches-section'}>
          <Container
            padingsVertical={['25px', '45px']}
            backgroundColor={backgroundColor}
          >
            <Row>
              <Col sm={sm} md={md} lg={lg} center>
                <Header>
                  <FormattedMessage id="module.popular_searches.headline">
                    {text => (
                      <SubsectionTitle>{text.toString()}</SubsectionTitle>
                    )}
                  </FormattedMessage>
                </Header>
                <ContentWrapper>
                  {languagePages.map((page: any, index: number) => (
                    <GatsbyLink
                      to={`/${intl.locale}/${page.node.slug}` || ''}
                      key={`SearchText_${index}`}
                    >
                      <SearchText>{page.node.linkName || page.node.title}</SearchText>
                    </GatsbyLink>
                  ))}
                </ContentWrapper>
              </Col>
            </Row>
          </Container>
        </Wrapper>
      )
    }}
  />
)

export default injectIntl(PopularSearchesSection)
